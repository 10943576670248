import React from 'react'
import { Link } from 'gatsby'

const Header = ({ siteTitle }) => (
  <div>
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
      }}
    >
      <h1 style={{ margin: 0, textAlign: 'right', lineHeight: '1.45rem' }}>
        <Link to="/" style={{ textDecoration: 'none', 'color': '#000' }}>
          Atelier<br />Wasserhaus
        </Link>
      </h1>
    </div>
  </div>
)

export default Header
